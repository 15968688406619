<template>
	<div class="login_container">
		<div class="login_body">
			<div class="login-form">
				<!-- logo 设置 -->
				<div class="login-logo">
					<img class="logo" :alt="appName" src="/img/logo.png" />
					<h2>管理员登录</h2>
				</div>
				<el-form ref="ruleForm" :model="ruleForm" :rules="rules" status-icon label-width="0" size="large">
					<!-- 手机号 start -->
					<el-form-item prop="user">
						<el-input v-model="ruleForm.user" prefix-icon="el-icon-user" clearable placeholder="请输入手机号" />
					</el-form-item>

					<!-- 密码 start -->
					<el-form-item prop="password">
						<el-input v-model="ruleForm.password" prefix-icon="el-icon-lock" clearable show-password placeholder="请输入密码" />
					</el-form-item>

					<!-- 验证码 start -->
					<el-form-item prop="validationCode" :error="codeErrmsg">
						<el-popover placement="bottom" trigger="hover" :width="350">
							<template #reference>
								<el-button style="width:100%;" :type="verifySelect.length == 3 ? 'success' : 'info'"><i class="sc-icon-shield-user-fill"></i> 获取验证码 </el-button>
							</template>
							<div>
								<!-- 验证码图片宽高需要与后台核对 -->
								<el-image :alt="appName" @click="verifyClick($event)" style="width: 320px; height: 170px" :src="ruleForm.OriginalImageBase64" fit="fit"></el-image>
								<el-badge :value="name + 1" v-for="(item, name) in verifySelect" v-bind:key="item" :style="{ left: item.X + 'px', top: item.Y + 'px' }" class="item"></el-badge>
								<el-row>
									<el-col :span="12">
										<el-button type="text"> 请依次点击：{{ ruleForm.WordList }} </el-button>
									</el-col>
									<el-col :span="12" style="text-align: right">
										<el-button icon="el-icon-refresh" @click="GetCaptcha"> 刷新 </el-button>
									</el-col>
								</el-row>
							</div>
						</el-popover>
					</el-form-item>

					<!-- 忘记密码 start -->
					<el-form-item style="margin-bottom: 10px">
						<el-row>
							<el-col :span="12">
								<el-checkbox v-model="ruleForm.autologin" label="记住我" />
							</el-col>
							<el-col :span="12" style="text-align: right">
								<el-button type="text"> 忘记密码？ </el-button>
							</el-col>
						</el-row>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="login" style="width: 100%" :loading="islogin" round>
							登 录
						</el-button>
					</el-form-item>
				</el-form>

				<!-- <el-divider>其他登录方式</el-divider>

                <div class="login-oauth">
                    <el-button size="medium" type="primary" icon="sc-icon-qq-fill" circle />
                    <el-button size="medium" type="success" icon="sc-icon-wechat-fill" circle />
                    <el-button size="medium" type="info" icon="sc-icon-apple-fill" circle />
                    <el-button size="medium" type="warning" icon="el-icon-menu" circle />
                </div> -->
			</div>
			<div class="login-sidebox">
				<div class="login-sidebox__title">
					<h2>盛世星创</h2>
					<h4>高性能 / 精致 / 优雅 / 智慧校园解决方案</h4>
					<p></p>
				</div>
				<img src="/img/loginbg.svg" />
			</div>
		</div>
		<div class="login-footer">{{ appName }} {{ appVar }}</div>
		<div class="login-footer">
			<a href="http://beian.miit.gov.cn/" target="_blank">ICP证:黔ICP备2022009740号-1</a>
			<a href="https://beian.mps.gov.cn/#/query/webSearch?code=52030202001990" rel="noreferrer" target="_blank"><img  class="logos"  src="/img/beian.png" />贵公网安备52030202001990</a>
		</div>
	</div>
</template> 

<style scoped>
.logos{
    display: inline;
    margin: 0px 5px;
    width: 16px;
}

.item {
	position: absolute;
	top: 12px;
	left: 12px;
}
.login_container {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 1100px;
	margin: 0 auto;
	z-index: 1;
	transform: translate(-50%, -50%);
}
.login_body {
	width: inherit;
	display: flex;
	box-shadow: 0px 20px 80px 0px rgba(0, 0, 0, 0.3);
}
.login-sidebox {
	width: 50%;
	padding: 60px;
	color: #fff;
	background: #3887e5;
	position: relative;
	overflow: hidden;
}
.login-sidebox__title h2 {
	font-size: 30px;
}
.login-sidebox__title h4 {
	font-size: 18px;
	margin-top: 10px;
	font-weight: normal;
}
.login-sidebox__title p {
	font-size: 14px;
	margin-top: 10px;
	line-height: 1.8;
	color: rgba(255, 255, 255, 0.6);
}
.login-sidebox img {
	position: absolute;
	left: -120px;
	bottom: -160px;
	width: 550px;
}

.login-logo {
	text-align: center;
	margin-bottom: 30px;
}
.login-logo .logo {
	width: 70px;
	height: 70px;
	vertical-align: bottom;
}
.login-logo h2 {
	font-size: 24px;
	margin-top: 20px;
	color: #40485b;
}

.login-title {
	margin-top: 20px;
}
.login-title h2 {
	font-size: 22px;
	font-weight: normal;
}
.login-title p {
	font-size: 12px;
	margin-top: 40px;
	line-height: 1.8;
	color: rgba(255, 255, 255, 0.8);
}

.login-form {
	width: 50%;
	padding: 60px 100px;
	background: #fff;
}
.login-oauth {
	display: flex;
	justify-content: space-around;
}
.login-form .el-divider {
	margin-top: 40px;
}

.login-footer {
	text-align: center;
	color: #999;
	margin-top: 50px;
}

.demo-user-item {
	display: flex;
	align-items: center;
	line-height: 1;
	padding: 10px 0;
}
.demo-user-item .icon {
	margin-right: 20px;
}
.demo-user-item .info h2 {
	font-size: 14px;
}
.demo-user-item .info p {
	color: #666;
	margin-top: 6px;
}

@media (max-height: 650px) {
	.login_container {
		position: static;
		transform: none;
		margin: 50px auto;
	}
	.login-footer {
		margin-bottom: 50px;
	}
}
@media (max-width: 1200px) {
	.login_container {
		width: 900px;
	}
	.login-form {
		padding: 60px;
	}
}
@media (max-width: 1000px) {
	.login_container {
		width: 100%;
		background: #fff;
		margin: 0;
		transform: none;
		top: 0px;
		bottom: 0px;
		left: 0px;
		right: 0px;
	}
	.login_body {
		box-shadow: none;
	}
	.login-form {
		width: 100%;
		padding: 60px 40px;
	}
	.login-sidebox {
		display: none;
	}
	.login-footer {
		margin-top: 0;
	}
}
</style>

<script>
export default {
	data() {
		var CodeValidation = (rule, value, callback) => {
			if (this.verifySelect.length < 3) {
				this.GetCaptcha();
				callback(new Error("验证码错误"));
			} else callback();
		};
		return {
			appName: this.$CONFIG.APP_NAME,
			appVar: this.$CONFIG.APP_VER,
			ruleForm: {
				user: "",
				password: "",
				OriginalImageBase64: "", //验证码图片
				WordList: "", //输入的文字
				autologin: false,
			},
			//验证方式
			rules: {
				user: [
					{
						validator: this.$TOOL.FormValidation.phoneValidation,
						trigger: "blur",
					},
				],
				password: [
					{
						validator: this.$TOOL.FormValidation.passwordValidation,
						trigger: "blur",
					},
				],
				validationCode: [
					{
						validator: CodeValidation,
					},
				],
			},
			// 是否显示登陆按钮加载
			islogin: false,
			// 验证码图片显示
			visible: false,
			//图片验证用户选中的点
			verifySelect: [],
			//后端验证token
			token: null,
			//验证码错误提示
			codeErrmsg: "",
		};
	},

	created: function() {
		//清空信息
		this.$TOOL.data.remove("adminInfo");
		this.$TOOL.data.remove("grid");
		this.$TOOL.data.remove("my-mods");
		this.$store.commit("clearViewTags");
		this.$store.commit("clearKeepLive");
		this.$store.commit("clearIframeList");
		//获取验证码
		this.GetCaptcha();
	},
	methods: {
		login: function() {
			this.islogin = true;
			var that = this;
			that.codeErrmsg = "";
			this.$refs["ruleForm"].validate((valid) => {
				if (valid) {
					that.$API.AdminAuth.AdminLogin.post({
						account: this.ruleForm.user,
						password: this.ruleForm.password,
						clickWordCaptcha: {
							captchaType: "clickWordCaptcha",
							pointJson: this.verifySelect,
							token: this.token,
						},
					}).then((res) => {
						if (res.Code == 500) {
							that.GetCaptcha();
							that.islogin = false;
							that.codeErrmsg = res.Message;
							return;
						}
						//将数据存入缓存
						that.$TOOL.data.set("adminInfo", res);
						//跳转路由
						that.$router.replace({
							path: "/",
						});
						//开启欢迎词
						that.$message.success("Login Success 登录成功");
					});

					//this.islogin = false;
				} else {
					this.islogin = false;
					return false;
				}
			});
		},
		//获取验证码
		GetCaptcha: function() {
			var that = this;
			this.codeErrmsg = "";
			this.$API.AdminAuth.GetCaptcha.post().then((res) => {
				//验证图片
				that.ruleForm.OriginalImageBase64 = `data:image/jpeg;base64,${res.OriginalImageBase64}`;
				that.ruleForm.WordList = "";
				var WordList = "";
				//获取验证文字
				res.WordList.forEach((element) => {
					WordList += `${element},`;
				});
				//去掉最后一个字符,
				that.ruleForm.WordList = that.$TOOL.removeLastComma(WordList);
				//清空选择内容
				that.verifySelect = [];
				//保存验证码token
				that.token = res.Token;
			});
		},
		//点击验证码
		verifyClick: function(event) {
			//判断是否继续执行
			if (this.verifySelect.length >= 3) return false;
			//获取容器坐标
			let el = document.getElementsByClassName("el-popover")[0];
			let transform = el.style.transform
				.replace("translate(", "")
				.replace(")", "")
				.replace("px", "")
				.replace("px", "");
			let transformInfo = transform.split(", ");
			//容器x,y坐标
			let panllX = parseInt(transformInfo[0]),
				panllY = parseInt(transformInfo[1]);
			let point = {
				X: event.x - panllX - 12,
				Y: event.y - panllY - 12,
			};
			this.verifySelect.push(point);
		},
	},
};
</script>
